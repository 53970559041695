import * as React from 'react'

const NotFoundPage = () => {
  return (
    <main
      style={{
        color: '#232129',
        padding: '96px',
        fontFamily: '-apple-system, Roboto, sans-serif, serif',
      }}
    >
      <title>Not found</title>
      <h1 style={{ marginBottom: 64, maxWidth: 320 }}>Page not found</h1>
    </main>
  )
}

export default NotFoundPage
